<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/contact/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content">
      <div class="top">
        <div class="title">联系我们</div>
        <div class="line"></div>
        <div class="title1">CONTACT US</div>
      </div>
      <div class="bom">
        <div class="text">
          <div class="item">公司：浙江骐骥软件科技有限公司</div>
          <div class="item">电话：18006607370</div>
          <div class="item">邮箱：ykzhou@qi-soft.com</div>
          <div class="item">地址：浙江省嘉兴市经济技术开发区昌盛南路36号11幢404室</div>
        </div>
        <div id="container" class="map"></div>
      </div>
    </div>
<!--    <floatBar></floatBar>-->
  </div>
</template>

<script>
// import floatBar from "../../components/FloatBar";
import AMapLoader from '@amap/amap-jsapi-loader';
import { get, post } from '../../utils/http.js'
export default {
  metaInfo: {
    title: "浙江骐骥软件科技",
    meta: [
      {
        name: "Contact",
        content: "联系我们",
      },
    ],
  },
  components: { },
  props: {},
  data() {
    return {
      center: { lng: 120.722211, lat: 30.734418 },
      zoom: 20,
      datalist:{},
      map:null,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.toTop();
    this.lode()
  },
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj"
      }
      let url = "/login"
      post(url,data).then(res=>{
        localStorage.setItem("token",res.token);
        this.ceshi()
      })
    },
    ceshi() {
      let url = "/portal/company/6"
      get(url,null).then(res=>{
        this.datalist = res.data.company
      })
      this.initMap()
    },
    initMap(){
      AMapLoader.load({
        key:"d058216968834ddf62c4be2b04d43586",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"2D",    //是否为3D地图模式
          zoom:17,           //初始化地图级别
          center:[120.71533,30.726238], //初始化地图中心点位置
        });
        let marker = new AMap.Marker({
          position: new AMap.LngLat(120.71533,30.726238),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: '浙江骐骥软件科技',
        });
// 将创建的点标记添加到已有的地图实例：
        this.map.add(marker);
      }).catch(e=>{
        console.log(e);
      })
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },

  mounted() {},
};
</script>
<style lang="less" scoped>
#container{
  padding:0px;
  margin: 0px;
  width: 800px;
  height: 300px;
}
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.content {
  width: 100%;
  padding-top: 70px;
  .top {
    text-align: center;
    .title {
      color: #000028;
      font-size: 24px;
    }
    .line {
      width: 40px;
      height: 1px;
      background: #ef8200;
      opacity: 1;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 7px;
    }
    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }
  .bom {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    .text {
      width: 340px;
      border-bottom: 2px solid #f4f4f4;
      .item {
        font-size: 16px;
        font-weight: 400;
        color: #000014;
        line-height: 40px;
      }
      .item:hover {
        color: #ef8200;
      }
    }
    .map {
      width: 800px;
      height: 300px;
      background: pink;
      margin: 0 auto;
    }
  }
}
</style>
